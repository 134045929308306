import React, {useEffect, useState} from "react"
import { Link } from "gatsby-plugin-intl"

import {useIntl} from "react-intl";
import PropTypes from "prop-types";
import instance from "../../components/api/httpclient";

import Slider from "react-slick";
import ENV from "../../../env";

const OfferSlider = ({offers}) => {
  const intl = useIntl();

  const settings = {
    infinite: false,
    dots: false,
    arrow: true,
    centerMode: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        arrow: true,
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        arrow: true,
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        arrow: true,
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const offersHtml = () => {

    if (offers && offers.length > 0) {
      return (
        offers.map((offer, index) =>
          (
            <div key={index + 1}>
              <Link to="/offers/offer-single" className="reward-item" key={index} state={{id: offer.id}} >
                <div className="img-wrapper" style={{backgroundImage: "url(" + offer.image + ")"}}>
                </div>
                <div className="reward-content-wrapper">
                  <div className="reward-title text"><strong className="darker-grey">{offer.name}</strong></div>
                  <p className="reward-description">{offer.description}</p>
                  {/*<div class="mb-3 text-center"><Link to="/offers/offer-single" className="button white mx-auto" state={{id: offer.id}}>{intl.formatMessage({id: "offers.learn_more"})}</Link></div>*/}
                  <div className="text-center"><a className="button white mx-auto mt-2" href="#"><span>{intl.formatMessage({id: "offers.learn_more"})}</span></a></div>
                </div>
              </Link>
            </div>
          )
        )
      )
    }
  };

  return(
    <div className="reward-row">
      <Slider {...settings}>
        {offersHtml()}
      </Slider>
    </div>
  )



}

OfferSlider.propTypes = {
  offers: PropTypes.node.isRequired,
}


export default OfferSlider
