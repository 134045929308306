const CONSTANTS = {
  API_URL: 'https://api.nissannow.nl',
  BE_YOUPLUS_URL: 'nissannow.be',
  BE_YOUPLUS_URL_2: 'www.nissannow.be',
  NL_YOUPLUS_URL: 'nissannow.nl',
  NL_YOUPLUS_URL_2: 'www.nissannow.nl',
  LU_YOUPLUS_URL: 'nissannow.lu',
  LU_YOUPLUS_URL_2: 'www.nissannow.lu'
}

export default CONSTANTS;
